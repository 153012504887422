import React from 'react'
import t from 'prop-types'

import { Tabs, WeeksContainer, EmptyStateContainer } from './components'
import { Week } from './week'
import { EmptyState } from 'ui/empty-state'

const StudyListWeeksCreate =
  ({
    onAddClick,
    onRemoveClick,
    onCloseClick,
    onDragEnd,
    disciplines = [],
    amountOfWeeks,
  }) => {
    const isEmpty = disciplines.length === 0 || amountOfWeeks === 0

    if (isEmpty) {
      return (
        <EmptyStateContainer>
          <EmptyState
            imageSize={90}
            description='Selecione as disciplinas e informe o número de semanas.'
          />
        </EmptyStateContainer>
      )
    }

    return (
      <Tabs animated={false}>
        {disciplines.map(({ name, id: disciplineId, weeks }) => (
          <Tabs.Pane tab={name} key={disciplineId}>
            <WeeksContainer scroll>
              {weeks.map((week, id) => {
                return (
                  <Week
                    onDragEnd={result => onDragEnd(result, disciplineId, id)}
                    onAdd={_ => onAddClick(id, disciplineId)}
                    onRemove={resourceId => onRemoveClick(disciplineId, id, resourceId)}
                    key={id}
                    items={week.resources}
                    id={id}
                  />
                )
              })}
            </WeeksContainer>
          </Tabs.Pane>
        ))}
      </Tabs>
    )
  }
StudyListWeeksCreate.propTypes = {
  disciplines: t.array,
  onAddClick: t.func.isRequired,
  onCloseClick: t.func.isRequired,
  onRemoveClick: t.func.isRequired,
  onDragEnd: t.func.isRequired,
  amountOfWeeks: t.number.isRequired,
}

export { StudyListWeeksCreate }
