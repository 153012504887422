import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'

import { useSubjectsFetcher } from './use-subjects-fetcher'

const SubjectSelect = ({
  loading,
  onlyParents,
  parentSlug,
  parentId,
  disciplineSlug,
  valueName = 'slug',
  ...antdProps
}) => {
  const { subjects } = useSubjectsFetcher({ disciplineSlug, onlyParents, parentSlug, parentId })
  return (
    <Select
      {...antdProps}
      data-testid='subjects-select'
      emptyText='Nenhum assunto encontrado'
      loading={loading}
    >
      {subjects.map(({ name, ...subject }) => (
        <Option value={subject[valueName]} key={subject[valueName]}>{name}</Option>
      ))}
    </Select>
  )
}

SubjectSelect.propTypes = {
  loading: t.bool,
  onlyParents: t.bool,
  parentSlug: t.string,
  parentId: t.string,
  valueName: t.oneOf(['id', 'slug']),
  disciplineSlug: t.string,
}

export { SubjectSelect }
