import { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { getNodes } from 'resources'

export const SUBJECTS_QUERY = gql`
  query GetSubjects($filter:FilterSubjectsInput!){
    subjects (filter: $filter){
     nodes {
        id
       slug
       name
      }
    }
  }
`

const useSubjectsFetcher = ({ onlyParents, parentSlug, disciplineSlug, parentId }) => {
  const { data, refetch, loading } = useQuery(SUBJECTS_QUERY, {
    variables: {
      filter: {
        onlyParents,
        parentSlug,
        parentId,
        disciplineSlug,
        showAll: true,
      },
    },
  })

  useEffect(() => {
  }, [parentSlug, refetch])

  return {
    subjects: getNodes('subjects', data),
    isFetching: loading,
  }
}

export { useSubjectsFetcher }
